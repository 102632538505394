import React, { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { toast } from 'react-toastify';
import SelectField from '../../../components/forms/select';
import Loading from '../../../components/global/loading';
import { saveRaceTimetable, getRaceTimetable } from '../../../api/timetables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { faCaretDown, faCaretUp, faCommentMedical, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const RaceTimetable = (props) => {
	const [loading, setLoading] = useState(true);
	const [crews, setCrews] = useState(null);
	const [allRows, setAllRows] = useState([]);
	const [day, setDay] = useState('tues');

	const getEvents = useSelector((state) => state.events);

	const customFilterOption = (option, rawInput) => {
		const words = rawInput.split(' ').filter((word) => word.length > 0);
		const pattern = words.map((word) => `(?=.*\\b${word})`).join('');
		const regex = new RegExp(`${pattern}`, 'i');
		return regex.test(option.label);
	};

	const fetchTimetableData = async () => {
		const fetchTimetable = await getRaceTimetable(day);
		if (fetchTimetable.data.status === 'success') {
			setAllRows(fetchTimetable.data.timetable);

			// order crews by name
			const crewsNameList = fetchTimetable.data.crews.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}
				if (a.label > b.label) {
					return 1;
				}
				return 0;
			});


			setCrews(crewsNameList);
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchTimetableData();
	}, [day]);


	if (loading) {
		return <Loading />;
	}

	const changeDay = (e) => {
		const { value } = e.target;
		setLoading(true);
		setDay(value);
	};

	const doSubmit = async (values) => {
		const toastId = toast('Saving Race Timetable...', { autoClose: false, type: 'info' });

		try {
			const pushData = await saveRaceTimetable(values, day);
			if (pushData.data.status === 'success') {
				toast.update(toastId, { type: 'success', render: "Timetable Updated", autoClose: 5000 });
			}
		} catch (error) {
			toast.update(toastId, { type: 'error', render: "Timetable Update Error", autoClose: 5000 });
		}
	};

	const TimeTableForm = () => {
		let raceNum = 0;
		const initialValues = {
			events: allRows.map((row, index) => {
				raceNum++;

				if (row.type === 'BREAK') {
					raceNum--;
				}

				return {
					id: row.id || index, // Ensure each row has a unique identifier
					type: row.type || null,
					start_time: row.start_time || null,
					bucks_id: row.bucks_id || null,
					bucks_id_2: row.bucks_id_2 || false,
					bucks_or: (row.bucks_id_2) ? true:false,
					berks_id: row.berks_id || null,
					berks_id_2: row.berks_id_2 || false,
					berks_or: (row.bucks_id_2) ? true : false,
					event_id: row.event_id || 0,
					race: row.race || raceNum,
					commentary: row.commentary || null,
					break: row.break || null
				};
			})
		};


		return (
			<Formik
				// enableReinitialize
				initialValues={initialValues}
				validationSchema={Yup.object().shape({
					events: Yup.array().of(
						Yup.object().shape({
							start_time: Yup.string()
								.required('Time is required')
								// should be in the format HH:MM 24 hour clock
								.matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Time must be in the format HH:MM 24 hour format. e.g 09:00')
								.min(5, 'Time must be 5 characters long e.g 09:00')
								.max(5, 'Time must be 5 characters long e.g 09:00'),
						})
					),
				})}
				onSubmit={doSubmit}
			>
				{({values, setFieldValue, isSubmitting}) => {
					let raceNum = 0;

					return (
						<Form className="timetable">
							<table className="table table-striped align-middle">
								<thead>
									<tr>
										<th width="120px"></th>
										<th width="30px">Race Number</th>
										<th width="120px">Starting Time</th>
										<th>Berks</th>
										<th>Bucks</th>
										<th>Event</th>
									</tr>
								</thead>
								<FieldArray
									name="events"
									render={arrayHelpers => (
										<tbody>
											{values.events.map((event, index) => {
												if (event.type === 'RACE') {
													raceNum++;
													// find value in crews of berks_id and return event_name
													let selectedEvent = null;
													if (crews && values.events[index]?.berks_id && values.events[index]?.berks_id !== 100000 && values.events[index]?.berks_id !== 200000) {
														selectedEvent = crews.filter(crew => crew.value === values.events[index]?.berks_id).map(crew => crew.event_id);
														// crews.push({ label: "Scull Over", value: 200000 });
														// crews.push({ label: "Row Over", value: 100000 });
													} else if (crews && values.events[index]?.bucks_id && values.events[index]?.bucks_id !== 100000 && values.events[index]?.bucks_id !== 200000) {
														selectedEvent = crews.filter(crew => crew.value === values.events[index]?.bucks_id).map(crew => crew.event_id);
														// crews.push({ label: "Scull Over", value: 200000 });
														// crews.push({ label: "Row Over", value: 100000 });
													}

													// make sure crews always has { label: "Scull Over", value: 100000 } and { label: "Scull Over", value: 200000 }


													const filteredCrews = (crews && selectedEvent) ? crews
														.filter(crew =>
															crew.event_id === selectedEvent[0] && crew.value !== values.events[index]?.berks_id
														):crews;

														// push scull over and row over to the filtered crews if they are not already there
														if (crews && selectedEvent) {
															if (!filteredCrews.find(crew => crew.value === 100000)) {
																filteredCrews.push({ label: "Row Over", value: 100000 });
															}
															if (!filteredCrews.find(crew => crew.value === 200000)) {
																filteredCrews.push({ label: "Scull Over", value: 200000 });
															}
														}

													return (
														<React.Fragment key={`${event.id}`}>
															<tr>
																<td className="text-center">
																	<button type="button" className="btn btn-none w-50" onClick={() => arrayHelpers.move(index, index - 1)}><FontAwesomeIcon icon={faCaretUp}/></button>
																	<button type="button" className="btn btn-none w-50" onClick={() => arrayHelpers.move(index, index + 1)}><FontAwesomeIcon icon={faCaretDown} /></button>
																	<button type="button" className="btn btn-success w-50" onClick={() => arrayHelpers.insert(index + 1, {type: 'RACE'})}><FontAwesomeIcon icon={faPlus} /></button>
																	<button type="button" className="btn btn-delete w-50" onClick={() => arrayHelpers.remove(index)}><FontAwesomeIcon icon={faTrash} /></button>
																</td>
																<td><Field name={`events[${index}].race`} type="number" className="form-control" value={raceNum} disabled /></td>
																<td>
																	<Field name={`events[${index}].start_time`} type="text" className="form-control" />
																	<ErrorMessage name={`events[${index}].start_time`} component="div" className="error-message" />
																</td>
																<td>
																	<div className="row align-items-center mb-1">
																		<div className="col-10">
																			<Field component={SelectField} filterOption={customFilterOption} options={crews} isClearable={true} name={`events[${index}].berks_id`} onChange={
																				(e) => {
																					const eventid = crews.filter(crew => crew.value === e).map(crew => crew.event_id);
																					setFieldValue(`events[${index}].event_id`, eventid[0]);
																					setFieldValue(`events[${index}].bucks_id`, null);
																					setFieldValue(`events[${index}].bucks_id_2`, null);
																					setFieldValue(`events[${index}].berks_id_2`, null);
																				}
																			} />
																		</div>
																		<div className="col-2">
																			<div className="form-check">
																				<Field type="checkbox" name={`events[${index}].berks_or`} className="form-check-input" />
																				<label className="form-check-label">
																					or
																				</label>
																			</div>
																		</div>
																	</div>

																	{(values.events[index]?.berks_or) &&
																		<div className="row">
																			<div className="col-10">
																				<Field component={SelectField} filterOption={customFilterOption} options={filteredCrews} isClearable={true} name={`events[${index}].berks_id_2`} />
																			</div>
																		</div>
																	}
																</td>
																<td>
																	<div className="row align-items-center mb-1">
																		<div className="col-10">
																			<Field component={SelectField} filterOption={customFilterOption} options={filteredCrews} isClearable={true} name={`events[${index}].bucks_id`} onChange={
																				(e) => {
																					const eventid = crews.filter(crew => crew.value === e).map(crew => crew.event_id);
																					setFieldValue(`events[${index}].event_id`, eventid[0]);
																					// setFieldValue(`events[${index}].bucks_id`, null);
																					// setFieldValue(`events[${index}].bucks_id_2`, null);
																					// setFieldValue(`events[${index}].berks_id_2`, null);
																				}
																			} />
																			{/* <Field component={SelectField} filterOption={customFilterOption} options={filteredCrews} isClearable={true} name={`events[${index}].bucks_id`} /> */}
																		</div>
																		<div className="col-2">
																			<div className="form-check">
																				<Field type="checkbox" name={`events[${index}].bucks_or`} className="form-check-input" />
																				<label className="form-check-label">
																					or
																				</label>
																			</div>
																		</div>
																		<div className="col-12 mt-1">
																			{(values.events[index]?.bucks_or) &&
																				<div className="row">
																					<div className="col-10">
																					<Field component={SelectField} filterOption={customFilterOption} options={filteredCrews} isClearable={true} name={`events[${index}].bucks_id_2`} />
																					</div>
																				</div>
																			}
																		</div>
																	</div>
																</td>
																<td>
																	{getEvents.events.find(event => event.id === values.events[index]?.event_id)?.name}
																	<Field name={`events[${index}].event_id`} type="hidden" disabled />
																</td>
																<td>
																	<button type="button" onClick={() => setFieldValue(`events[${index}].commentary`, 'Add Commentary')} className="btn btn-success"><FontAwesomeIcon icon={faCommentMedical} /></button>
																</td>
															</tr>
															<tr className={(!values.events[index]?.commentary) && 'd-none'}>
																<td colSpan="8">
																	<Field name={`events[${index}].commentary`} component="textarea" className="form-control" />
																</td>
															</tr>
														</React.Fragment>
													);
												} else if (event.type === 'BREAK') {
													return (
														<tr key={`${event.id}`} className="timetable--break" data-id={event.id}>
															<td className="text-center">
																<button type="button" className="btn btn-none text-white" onClick={() => arrayHelpers.move(index, index - 1)}><FontAwesomeIcon icon={faCaretUp} /></button>
																<button type="button" className="btn btn-none text-white" onClick={() => arrayHelpers.move(index, index + 1)}><FontAwesomeIcon icon={faCaretDown} /></button>
																<button type="button" className="btn btn-success w-50" onClick={() => arrayHelpers.insert(index + 1, { type: 'BREAK' })}><FontAwesomeIcon icon={faPlus} /></button>
																<button type="button" className="btn btn-delete w-50" onClick={() => arrayHelpers.remove(index)}><FontAwesomeIcon icon={faTrash} /></button>
															</td>
															<td></td>
															<td><Field name={`events[${index}].start_time`} type="text" className="form-control" /></td>
															<td colSpan="4" className="text-center">
																<Field name={`events[${index}].break`} className="form-control" />
															</td>
															<td></td>
														</tr>
													);
												}
											})}

										</tbody>
									)}
								/>
							</table>
							<div className="floating-buttons">
							<div className="row">
								<div className="col-12 text-center">
									<button type="submit" className="btn btn-primary" disabled={(isSubmitting) && true}>Save</button>
								</div>
							</div>
							</div>
						</Form>
					)
				}
				}
			</Formik>
		);
	};

	if (!loading && !crews) {
		return (
			<div className="container-fluid">
				<div className="row align-items-center mb-3">
					<div className="col-12">
						<h1 className="mb-0">Race Timetable</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-12 form-group">
						<div className="alert alert-danger">No crews were found with the correct status to be placed into a timetable.</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="container-fluid">
			<div className="row align-items-center mb-3">
				<div className="col-12 col-md-6">
					<h1 className="mb-0">Race Timetable</h1>
				</div>
				<div className="col-12 col-md-6">
					<select className="form-control" onChange={(e) => changeDay(e)} defaultValue={day}>
						<option value="tues">Tuesday</option>
						<option value="weds">Wednesday</option>
						<option value="thurs">Thursday</option>
						<option value="fri">Friday</option>
						<option value="sat">Saturday</option>
						<option value="sun">Sunday</option>
					</select>
				</div>
			</div>
			<div className="row">
				<div className="col-12 form-group">
					<div>
						{(allRows) && <TimeTableForm />}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(RaceTimetable);
