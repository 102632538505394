import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useJWT from './jwt/useJWT';
import jwtDecode from 'jwt-decode';

import Login from "./components/auth/login";
import Register from "./components/auth/register";
import ResetPassword from "./components/auth/resetPassword";
import ResetPasswordRequest from './components/auth/resetPasswordRequest';
// import Callback from "./components/auth/callback";
import Loading from "./components/global/loading";
import VerifyEmail from "./components/auth/verifyEmail";

// API
import { getEvents } from "./api/events";

// Pages
// import AdminDashboard from "./pages/admin/dashboard/dashboard";
import Dashboard from "./pages/dashboard";
import SavedEntries from "./pages/savedEntries";
import CompletedEntries from "./pages/CompletedEntries";
import Settings from "./pages/admin/settings/settings";
import Header from "./components/global/header";
import Nav from "./components/global/nav";
import AdminNav from "./components/global/adminNav";
import Footer from "./components/global/footer";
import logo from './assets/images/hrr-logo.svg';
import Entry from "./pages/entry";
import CreateEntry from "./pages/createEntry";
import Basket from "./pages/frontend/Basket";
import Bio from "./pages/Bio";
import Profile from "./pages/Profile";
import NotFound from "./pages/frontend/404";
import AthleteEntry from "./pages/AthleteEntry";
import CoachEntry from "./pages/CoachEntry";
import Emails from "./pages/admin/settings/emails";

// Admin Pages
import Entries from "./pages/admin/entries/entries";
import SingleEntry from "./pages/admin/entries/singleEntry";
import CommentatorCrewList from "./pages/admin/commentators/commentatorsCrewList";
import CommentatorList from "./pages/admin/commentators/commentatorsList";
import CommentatorSingle from "./pages/admin/commentators/commentatorsSingle";
import AthleteList from "./pages/admin/athletes/athletesList";
import AthleteSingle from "./pages/admin/athletes/athletesSingle";
import ClubsList from "./pages/admin/clubs/clubsList";
import ClubsSingle from "./pages/admin/clubs/clubSingle";
import CountriesSingle from "./pages/admin/countries/countriesSingle";
import CountriesList from "./pages/admin/countries/countriesList";
import NgbsList from "./pages/admin/ngbs/ngbsList";
import NgbsSingle from "./pages/admin/ngbs/ngbSingle";
import Logs from "./pages/admin/logs/logs";
import OrdersList from "./pages/admin/orders/ordersList";
import OrderSingle from "./pages/admin/orders/orderSingle";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import RaceTimetable from "./pages/admin/races/RaceTimetable";
// import RacesList from "./pages/admin/races/Races";
import RaceResults from "./pages/admin/races/Results";
import CouponList from "./pages/admin/coupons/couponList";
import CouponSingle from './pages/admin/coupons/couponSingle';
import BoatTent from './pages/admin/boat-tent-area/crewList';
import SingleBoatTent from './pages/admin/boat-tent-area/crewSingle';
import BoatTentWeighing from './pages/admin/boat-tent-area/weighingList';
import BoatTentWeighingSingle from './pages/admin/boat-tent-area/weighingSingle';
import Reports from './pages/admin/reports/Reports';
import QualifiersTimetable from './pages/admin/qualifiers/Timetable';
import AthleteDuplicateList from './pages/admin/athletes/athleteDuplicates';

import UserList from "./pages/admin/users/userList";
import UserSingle from "./pages/admin/users/userSingle";

import EventsList from "./pages/admin/events/eventsList";
import EventSingle from "./pages/admin/events/eventSingle";
import EventTypesList from "./pages/admin/events/eventTypesList";
import EventTypesSingle from "./pages/admin/events/eventTypesSingle";
import { ToastContainer } from 'react-toastify';

import OfflineStatusChecker from "./components/app/OfflineStatus";
import NgbApprovals from './pages/NgbApprovals';
import PaymentProcessing from './pages/paymentProcessing';
// import QualifyingGroups from './pages/admin/qualifiers/QualifyingGroups';
import QualifierGroups from './pages/admin/qualifiers/QualifierGroups';
import Qualifiers from './pages/admin/qualifiers/Qualifiers';
import QualifierResults from './pages/admin/qualifiers/Results';

import QualificationRules from './pages/frontend/qualificationRules';

import ScrollToTop from './utils/ScrollToTop';

const App = () => {
	const [loading, setLoading] = useState(true);
	const [getRoles, setRoles] = useState([]);
	const { jwt, isAuthenticated, isLoading } = useJWT();
	const dispatch = useDispatch();

	// const eventList = useSelector((state) => state.events);

	useEffect(() => {
		const fetchUserRoles = async () => {
			if (isAuthenticated) {
				try {
					const decodedToken = jwtDecode(jwt);
					const userRoles = decodedToken.role || [];
					setRoles(userRoles);
				} catch (error) {
					console.error('Error retrieving user roles and permissions:', error);
					// send to /login
					// navigator.navigate('/login');
					window.location.href = '/login';
				}

				try {
					// const accessToken = await getAccessTokenSilently();
					// localStorage.setItem('hrr_access_token', accessToken);

					// if (!eventList) {
						const eventData = await getEvents();
						if (eventData.data.status === 'success') {
							dispatch({ type: 'EVENTS', payload: eventData.data.events.data });
						}
						setLoading(false);
					// }
				} catch (error) {
					// console.error('Error retrieving user roles and permissions:', error);
					// send to /login
					// navigator.navigate('/login');
				}
			} else {
				console.log('GO LOGIN');
			}
		};

		fetchUserRoles().then(() => setLoading(false));

	}, [isAuthenticated]);

	useEffect(() => {
		
		if (isAuthenticated) {
			const fetchAllEvents = async () => {
				// if (!eventList) {
					try {
						const eventData = await getEvents();
						dispatch({ type: 'EVENTS', payload: eventData.data.events.data });
						setLoading(false);
					}
					catch (error) {
						console.error('Error retrieving events:', error);
						// send to /login
					}
				// }
			};

			fetchAllEvents();
		}
	}, [isAuthenticated]);

	if (loading || isLoading) {
		return <Loading />;
	}

	const rolesForAdmin = ['admin'];
	const rolesForRegattaAdmin = ['admin', 'regatta-admin'];
	const rolesForFinance = ['admin', 'regatta-admin', 'regatta-finance'];
	const rolesForCommentators = ['admin', 'regatta-admin', 'commentator', 'press-officer'];
	const rolesForBoatTent = ['admin', 'regatta-admin', 'commentator', 'boattent'];

	const checkRoles = (roleCheck) => {
		if (getRoles.length > 0) {
			return getRoles.some(i => roleCheck.includes(i));
		}

		return false;
	}

	return (
		<Router>
			<ScrollToTop />

			<div className="App">
				<OfflineStatusChecker />
				<Header/>

				<main>
					{(isAuthenticated) && (
							<aside>
								<img src={logo} alt="Henley Royal Regatta" className="img-fluid mb-4" />
								{(getRoles.length > 0) &&
									<>
										<Nav getRoles={getRoles} />
										<AdminNav getRoles={getRoles} />
									</>
								}
							</aside>
						)
					}

					<div className={(isAuthenticated) ? 'main-content logged-in':'main-content'}>
						<Routes>
							{/* Normie Routes */}
							{/* <Route path="/" element={(isAuthenticated) ? (checkRoles(rolesForRegattaAdmin)) ? <AdminDashboard />:<Dashboard /> : <Login />} /> */}
							<Route path="/" element={(isAuthenticated) ? <Dashboard /> : <Login />} />
							<Route path="/login" element={<Login />} />
							<Route path="/register" element={<Register />} />
							<Route path="/reset-password" element={<ResetPasswordRequest />} />
							<Route path="/confirm-password-reset" element={<ResetPassword />} />
							<Route path="/qualification-and-general-rules" element={<QualificationRules />} />

							<Route path="/verify" element={<VerifyEmail />} />
							{/* <Route path="/add-entry" exact element={(isAuthenticated) ? <CreateEntry />:<Login/>} /> */}
							<Route path="/entries/edit/:id" exact element={(isAuthenticated) ? <Entry />:<Login/>} />
							<Route path="/saved-entries" exact element={(isAuthenticated) ? <SavedEntries />:<Login/>} />
							<Route path="/my-entries" exact element={(isAuthenticated) ? <CompletedEntries />:<Login/>} />
							{/* <Route path="/basket" exact element={(isAuthenticated) ? <Basket /> : <Login />} /> */}
							<Route path="/payment/processing" exact element={(isAuthenticated) ? <PaymentProcessing /> : <Login />} />
							<Route path="/payment/confirmation" exact element={(isAuthenticated) ? <PaymentConfirmation /> : <Login />} />
							<Route path="/ngb-approvals" exact element={<NgbApprovals />} />
							<Route path="/bio" exact element={(isAuthenticated) ? <Bio /> : <Login />} />
							<Route path="/account" exact element={(isAuthenticated) ? <Profile /> : <Login />} />

							{
							/* Admin Routes */
							(checkRoles(rolesForAdmin)) &&
								<>
									<Route path="/admin/settings" exact element={(isAuthenticated) ? <Settings /> : <Login />} />
									<Route path="/admin/settings/emails" exact element={(isAuthenticated) ? <Emails /> : <Login />} />
								</>
							}

							{
							/* Regatta Admin Routes */
							(checkRoles(rolesForRegattaAdmin)) &&
								<>
									<Route path="/admin/entries" exact element={(isAuthenticated) ? <Entries /> : <Login />} />
									<Route path="/admin/entries/edit/:id" exact element={(isAuthenticated) ? <SingleEntry /> : <Login />} />
									<Route path="/admin/add-entry" exact element={(isAuthenticated) ? <CreateEntry />:<Login/>} />

									<Route path="/admin/athletes" exact element={(isAuthenticated) ? <AthleteList /> : <Login />} />
									<Route path="/admin/athletes/edit" exact element={(isAuthenticated) ? <AthleteSingle /> : <Login />} />
									<Route path="/admin/athletes/edit/:id" exact element={(isAuthenticated) ? <AthleteSingle /> : <Login />} />
									<Route path="/admin/athletes/duplicates" exact element={(isAuthenticated) ? <AthleteDuplicateList /> : <Login />} />
									
									<Route path="/admin/events" exact element={(isAuthenticated) ? <EventsList /> : <Login />} />
									<Route path="/admin/events/edit" exact element={(isAuthenticated) ? <EventSingle /> : <Login />} />
									<Route path="/admin/events/edit/:id" exact element={(isAuthenticated) ? <EventSingle /> : <Login />} />
									<Route path="/admin/event-types" exact element={(isAuthenticated) ? <EventTypesList /> : <Login />} />
									<Route path="/admin/event-types/edit" exact element={(isAuthenticated) ? <EventTypesSingle /> : <Login />} />
									<Route path="/admin/event-types/edit/:id" exact element={(isAuthenticated) ? <EventTypesSingle /> : <Login />} />

									<Route path="/admin/clubs" exact element={(isAuthenticated) ? <ClubsList /> : <Login />} />
									<Route path="/admin/clubs/edit" exact element={(isAuthenticated) ? <ClubsSingle /> : <Login />} />
									<Route path="/admin/clubs/edit/:id" exact element={(isAuthenticated) ? <ClubsSingle /> : <Login />} />

									<Route path="/admin/countries" exact element={(isAuthenticated) ? <CountriesList /> : <Login />} />
									<Route path="/admin/countries/edit" exact element={(isAuthenticated) ? <CountriesSingle /> : <Login />} />
									<Route path="/admin/ngbs" exact element={(isAuthenticated) ? <NgbsList /> : <Login />} />
									<Route path="/admin/ngbs/edit" exact element={(isAuthenticated) ? <NgbsSingle /> : <Login />} />
									<Route path="/admin/ngbs/edit/:id" exact element={(isAuthenticated) ? <NgbsSingle /> : <Login />} />

									<Route path="/admin/race-timetable" exact element={(isAuthenticated) ? <RaceTimetable /> : <Login />} />
									
									<Route path="/admin/qualifying-timetable" exact element={(isAuthenticated) ? <QualifiersTimetable /> : <Login />} />
									<Route path="/admin/qualifying-groups" exact element={(isAuthenticated) ? <QualifierGroups /> : <Login />} />
									<Route path="/admin/qualifiers" exact element={(isAuthenticated) ? <Qualifiers /> : <Login />} />
									<Route path="/admin/qualifying-results" exact element={(isAuthenticated) ? <QualifierResults /> : <Login />} />
									{/* <Route path="/admin/races" exact element={(isAuthenticated) ? <RacesList /> : <Login />} /> */}

									<Route path="/admin/race-results" exact element={(isAuthenticated) ? <RaceResults /> : <Login />} />

									<Route path="/admin/coupons" exact element={(isAuthenticated) ? <CouponList /> : <Login />} />
									<Route path="/admin/coupons/edit" exact element={(isAuthenticated) ? <CouponSingle /> : <Login />} />
									<Route path="/admin/coupons/edit/:id" exact element={(isAuthenticated) ? <CouponSingle /> : <Login />} />

									<Route path="/admin/users" exact element={(isAuthenticated) ? <UserList /> : <Login />} />
									<Route path="/admin/users/edit" exact element={(isAuthenticated) ? <UserSingle /> : <Login />} />
									<Route path="/admin/users/edit/:id" exact element={(isAuthenticated) ? <UserSingle /> : <Login />} />

									<Route path="/admin/logs" exact element={(isAuthenticated) ? <Logs /> : <Login />} />
									<Route path="/admin/reports" exact element={(isAuthenticated) ? <Reports /> : <Login />} />
								</>
							}

							{
							/* Regatta Admin Routes */
								(checkRoles(rolesForBoatTent)) &&
								<>
									<Route path="/admin/boat-tent" exact element={(isAuthenticated) ? <BoatTent /> : <Login />} />
									<Route path="/admin/boat-tent/edit/:id" exact element={(isAuthenticated) ? <SingleBoatTent /> : <Login />} />
									<Route path="/admin/boat-tent-weigh-in" exact element={(isAuthenticated) ? <BoatTentWeighing /> : <Login />} />
									<Route path="/admin/boat-tent-weigh-in/edit/:id" exact element={(isAuthenticated) ? <BoatTentWeighingSingle /> : <Login />} />
								</>
							}

							{
							/* Commentator Routes */
								(checkRoles(rolesForCommentators)) &&
								<>
									<Route path="/admin/commentators" exact element={(isAuthenticated) ? <CommentatorList /> : <Login />} />
									<Route path="/admin/commentators/crews" exact element={(isAuthenticated) ? <CommentatorCrewList /> : <Login />} />
									<Route path="/admin/commentators/:id" exact element={(isAuthenticated) ? <CommentatorSingle /> : <Login />} />
									<Route path="/admin/commentators/race/:id" exact element={(isAuthenticated) ? <CommentatorSingle /> : <Login />} />
								</>
							}

							{
							/* Commentator Routes */
								(checkRoles(rolesForFinance)) &&
								<>
									<Route path="/admin/orders" exact element={(isAuthenticated) ? <OrdersList /> : <Login />} />
									<Route path="/admin/orders/:id" exact element={(isAuthenticated) ? <OrderSingle /> : <Login />} />
								</>
							}

							{/* Athlete/Coach Routes */}
							<Route path="/athlete/bio/:hash" exact element={<AthleteEntry />} />
							<Route path="/coach/bio/:hash" exact element={<CoachEntry />} />

							<Route path="*" element={<NotFound />} />
						</Routes>
						<ToastContainer
							position="top-center"
						/>
					</div>
				</main>
				<Footer />
			</div>
		</Router>
	);
};

export default App;
