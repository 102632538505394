import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faFloppyDisk, faSquarePlus, faUser, faClipboardCheck, faBook } from '@fortawesome/free-solid-svg-icons'

const Nav = (getRoles) => {
	const checkRole = (role) => { return getRoles.getRoles.includes(role) }
	return (
		<nav>
			<ul>
				<li>
					<NavLink to="/"><span className="menu-icon"><FontAwesomeIcon icon={faHouse} /></span> <span className="menu-text">Home</span></NavLink>
				</li>
				{(checkRole('user')) &&
					<>
						{/* <li>
							<NavLink to={"/add-entry"} state={{ flush: true }}><span className="menu-icon"><FontAwesomeIcon icon={faSquarePlus} /></span> <span className="menu-text">Add Entry</span></NavLink>
						</li> */}
						<li>
							<NavLink to="/saved-entries"><span className="menu-icon"><FontAwesomeIcon icon={faFloppyDisk} /></span> <span className="menu-text">Saved Entries</span></NavLink>
						</li>
						<li>
							<NavLink to="/my-entries"><span className="menu-icon"><FontAwesomeIcon icon={faClipboardCheck} /></span> <span className="menu-text">My Entries</span></NavLink>
						</li>
					</>
				}
				{(checkRole('athlete') || checkRole('coach')) &&
					<li>
						<NavLink to="/bio"><span className="menu-icon"><FontAwesomeIcon icon={faUser} /></span> <span className="menu-text">Bio</span></NavLink>
					</li>
				}
				{(checkRole('user')) &&
					<>
						<li>
							<NavLink to={"/qualification-and-general-rules"}><span className="menu-icon"><FontAwesomeIcon icon={faBook} /></span> <span className="menu-text">Qualification & General Rules</span></NavLink>
						</li>
					</>
				}
			</ul>
		</nav>
	);
};

export default Nav;
