import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { getEvents } from '../../../api/events';

const EventsList = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	const [loading, setLoading] = useState(true);
	const [events, setEvents] = useState({});
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('display_order');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		try {
			const getEventsRes = await getEvents(page, sortDirection, sortedColumn);
			if (getEventsRes.data.status === 'success') {
				setEvents(getEventsRes.data.events);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		setLoading(true);
		getInitialData();
	}, [page, sortDirection, sortedColumn]);


	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setEvents(data);
	};

	const handleDeleteItem = async (id) => {
		if (window.confirm("Are you sure you want to delete this item?")) {
			try {
				// Make your fetch/axios call to delete the item from backend
				fetch(`${API_URL}/admin/event/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.error) {
							alert(data.error);
							return;
						}

						setEvents(prevData => prevData.filter(item => item.id !== id));
					})
					.catch((error) => {
						console.error('Error:', error);
					});
			} catch (error) {
				// Handle your error
				console.error('Error:', error);
			}
		}
	}

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(events.data).map(([key, value]) => {
			// console.log('value', value)
			return {
				view: 'admin',
				id: value.id,
				sort: parseInt(value.sort),
				qsort: parseInt(value.qsort),
				event_code: value.event_code,
				name: value.name,
				event_type_name: value.event_type_name,
				// initials: value.initials,
				// last_name: value.last_name,
				// club: value.club?.name,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Events <Link to="/admin/events/edit" className="btn btn-new"><FontAwesomeIcon icon={faPlusSquare} /></Link></h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search handleSearch={handleSearch} endpoint="admin/events/search" placeholder="Search Events by Name or Event Code" setLoading={setLoading} />
				</div>
				<div className="col-12 form-group">
					<div>
						{(events.data.length) ?
							<Table
								data={columns()}
								linkColumn="name"
								linkLocation="/admin/events/edit" 
								visibleColumns={[
									{
										name: 'event_code',
										sort: 'event_code'
									},
									{
										name: 'qsort',
										sort: 'qsort'
									},
									{
										name: 'sort',
										sort: 'sort'
									},
									{
										name: 'name',
										sort: 'name'
									},
									{
										name: 'event_type_name',
										sort: 'event_type_name'
									}
								]}
								onSort={handleSort}
								sortedColumn={sortedColumn}
								sortDirection={sortDirection}
								editable={true}
								handleDelete={handleDeleteItem}
							/> : <div className="alert alert-danger">No events found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(EventsList);
